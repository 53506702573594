import React, { useRef, useEffect, useState } from 'react';

function MessageMediaComponent(props) {
    const {
        media,
        index
    } = props;

    const ref = useRef(null);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const mediaType = media.type.startsWith('image/') ? 'image' : 'video';

    useEffect(() => {
        const loadImage = new Promise(async (resolve, reject) => {
            ref.current.src = await media.src(setDownloadProgress);
            resolve(true);
        }).then(() => {
            if (ref.current !== null)
                ref.current.parentElement.classList.add('loaded');
        });

        ref.current && loadImage.then((id) => {
            return id;
        })
    }, [ref, media]);


    return (
        <>
            {mediaType === 'video' ?
                <div key={"video_" + index} className={"message-attachment-link message-attachment-link-video"}>
                    <video draggable="false" ref={ref} controls>
                        <source src={media.src()} type={media.type} />
                    </video>
                </div> : null}
            {mediaType === 'image' ?
                <div key={"img_" + index} className={"message-attachment-link message-attachment-link-image"}>
                    <img
                        draggable="false"
                        style={
                            {
                                'aspect-ratio': 'auto ' + media.width + ' / ' + media.height,
                                'width': media.width + 'px',
                                'max-width': '100%'
                            }
                        }
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" ref={ref} alt={media.name}
                    />
                    <div className={"progress-bar"}>
                        {downloadProgress !== 100 ?
                            <div className={"progress-bar-fill"} style={{ width: downloadProgress + '%' }}>
                            </div>
                            : null}
                    </div>
                </div>
                : null}
        </>
    )
}

export default MessageMediaComponent;