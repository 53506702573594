import ReactQuill from 'react-quill-new';
import 'quill/dist/quill.snow.css';
import axios from 'axios';
import crypto from 'crypto';
import AddedDocumentComponent from './document';
import {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useTransition
} from 'react';

const QuillComponent = forwardRef((props, ref) => {

  const {
    placeholder,
    mqttClient,
    topicClient: currentTopic,
    userObject,
    dropzoneRef,
    messagePanelRef,
    signedEndpoint,
    editMessageId,
    editMessage,
    attachedDocuments,
    setAttachedDocuments,
    maxFiles,
    maxFilesize,
  } = props;

  const [value, setValue] = useState('');
  const [mqttPublish, setMQTTPublish] = useState(null);
  const [documentUploadProgress, setDocumentUploadProgress] = useState({});
  const [isPending, startTransition] = useTransition();

  const lastActiveTimer = useRef({
    id: null,
    value: 0
  });

  const quillRef = ref;
  const documentRefs = useRef([]);
  const chatForm = useRef();
  const stopForm = useRef(false);
  const debounceTimeout = useRef(null);
  const maxLength = props.maxCharacters;

  useEffect(() => {
    var quillRemove = document.getElementsByClassName('ql-tooltip')[0]
    quillRemove.remove();
  }, []);

  useEffect(() => {
    if (!chatForm || !chatForm.current) return;
    var quillContainer = quillRef.current.editor.root;
    const resizeObserver = new ResizeObserver(() => {
      if (!messagePanelRef.current) return;
      let messagePanelContainer = messagePanelRef.current.parentElement;
      let messagePanelContainerHeight = messagePanelContainer.clientHeight;
      var offset = messagePanelRef.current.offsetTop + 122;

      if (messagePanelContainer.clientWidth > 529) {
        offset += 20;
      }

      if (
        messagePanelContainerHeight - (chatForm.current.clientHeight + offset) > 295
        && chatForm.current.clientHeight < (messagePanelContainerHeight + offset)
      ) {
        messagePanelRef.current.style.height = "calc(100vh - " + (chatForm.current.clientHeight + offset) + "px)";
        messagePanelRef.current.style.maxHeight = "calc(100vh - " + (chatForm.current.clientHeight + offset) + "px)";
        stopForm.current = false;

        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
          var sidePanel = document.querySelector('.chat-references');
          if (sidePanel.classList.contains('unfold')) {
            sidePanel.classList.add('no-animation');
            sidePanel.style.height = "calc(100vh - " + (chatForm.current.clientHeight + offset - 31) + "px)";
            setTimeout(() => {
              sidePanel.classList.remove('no-animation');
            }, 25);
          }
        }, 40);

      } else if (!stopForm.current) {
        stopForm.current = setTimeout(() => {
          messagePanelRef.current.style.maxHeight = messagePanelRef.current.clientHeight + "px";
          quillContainer.style.maxHeight = "calc(100vh - " + (messagePanelRef.current.clientHeight + offset + 120) + "px)";
          quillContainer.style.overflow = "auto";
          stopForm.current = true;
        }, 10);
      }

    });
    resizeObserver.observe(chatForm.current);
    return () => resizeObserver.disconnect();
  }, [messagePanelRef, chatForm, quillRef]);

  const removeFile = useCallback(async (file) => {
    if (document.getElementById('attachment_' + file.name.replace(".", "")) !== null)
      document.getElementById('attachment_' + file.name.replace(".", "")).classList.remove("uploaded");
    let newFiles = attachedDocuments.document.filter((doc) => doc.name !== file.name);
    attachedDocuments.document = newFiles;
    props.setAttachedDocuments({ document: newFiles });
  }, [attachedDocuments, props]);

  useEffect(() => {
    if (mqttPublish === null || mqttClient === null)
      return;
    mqttPublish.forEach(item => {
      mqttClient.publish(item.topic, JSON.stringify(item));
      var delayFinish = new Date().getTime() + 80;
      while (new Date().getTime() < delayFinish) { }
    });
    setMQTTPublish(null);
  },
    [mqttPublish, mqttClient]);

  const clearWYSIWYG = e => {
    e.preventDefault();
    if (editMessageId !== null) {
      editMessage(editMessageId);
    }
    quillRef.current.editor.root.innerHTML = "";
    quillRef.current.editor.root.setAttribute('data-placeholder', 'Enter your message here');
  }

  const handleSubmit = e => {
    e.preventDefault();
    startTransition(async () => {
      var quillEditor = quillRef.current.editor;
      var quillContent = quillEditor.root.innerHTML;
      if (quillContent === '' || quillContent === '<p><br></p>') {
        quillRef.current.editor.root.setAttribute('data-placeholder', 'Please enter a message');
        return;
      }
      var messageTimestamp = Date.now();// + timeDiff;
      var documentNode = [];

      if (attachedDocuments.document.length > 0) {
        var promises = attachedDocuments.document.map(async file => {
          documentRefs.current[file.name].classList.add("uploading");
          const filePutSignedUrl = {
            method: 'GET',
            mode: 'cors',
            headers: {
              "Accept": "application/json",
              "Authorization": userObject.current.t,
              "uuid": userObject.current.id
            }
          };

          let encodedFilename = encodeURIComponent(currentTopic.id + "/" + messageTimestamp + "/" + file.name);
          let url = signedEndpoint + "?file=" + encodedFilename + "&type=put&size=" + file.file.size;

          const fileResult = await fetch(url, filePutSignedUrl)
            .then(res => res.json())
            .then(async tokens => {
              var s3PutUrl = tokens.url;
              var uploader = new axios.create();
              uploader.defaults.timeout = 0;
              uploader.defaults.onUploadProgress = (progressEvent) => {
                setDocumentUploadProgress({ ...documentUploadProgress, [file.name]: Math.round((progressEvent.loaded / progressEvent.total) * 100) });
              };

              await uploader.put(s3PutUrl, file.contents, {
                headers: {
                  'Content-Type': file.type,
                  'Content-Encoding': 'binary',
                }
              });

              if (file.type.startsWith('image/')) {
                const img = new Image();

                img.src = URL.createObjectURL(file.file);
                await new Promise(resolve => {
                  img.onload = () => {
                    file.width = img.width;
                    file.height = img.height;
                    resolve();
                  };
                });
              }

              if (document.getElementById('attachment_' + file.name.replace(".", "")) !== null)
                document.getElementById('attachment_' + file.name.replace(".", "")).classList.add("uploaded");

              var fileObject = {
                name: file.name,
                path: currentTopic.id + "/" + messageTimestamp + "/" + file.name,
                type: file.type,
              }

              if ('width' in file && 'height' in file) {
                fileObject.width = file.width;
                fileObject.height = file.height;
              }

              return fileObject;
            });
          return fileResult;
        });
        documentNode = await Promise.all(promises);
      }

      const totalMessage = {
        alias: userObject.current.alias,
        user: userObject.current.id,
        message: quillContent,
        document: documentNode
      };

      const key = crypto
        .createHash('sha512')
        .update(currentTopic.id)
        .digest('hex')
        .substring(0, 32);
      const encryptionIV = crypto
        .createHash('sha512')
        .update(currentTopic.key)
        .digest('hex')
        .substring(0, 16);
      const cipher = crypto.createCipheriv('aes-256-cbc', key, encryptionIV);

      var encrypted = cipher.update(JSON.stringify(totalMessage), 'utf8', 'base64');
      encrypted += cipher.final('base64');

      const parts = encrypted.match(/.{1,180}/g);

      var editing = false;
      if (editMessageId !== null) {
        messageTimestamp = props.editMessageId;
        editing = true;
        editMessage(editMessageId);
      } else {
        quillEditor.root.setAttribute('data-placeholder', 'Enter your message here');
        quillEditor.deleteText(0, quillEditor.getLength());
        setAttachedDocuments({ document: [] });
      }

      setMQTTPublish(
        parts.map((item, index) => {
          return {
            user: userObject.current.id,
            message: { text: item, total: parts.length, part: index + 1, edited: editing },
            timestamp: messageTimestamp,
            sent: Date.now(),
            topic: 'chat/' + currentTopic.id,
            msgtype: 'message'
          };
        })
      );
      props.setError(null);
      setDocumentUploadProgress({});
      return true;
    });
  }

  const onChange = value => {
    if (value.length > maxLength) {
      props.setError('Maximum message length is ' + maxLength + ' characters');
      quillRef.current.editor.root.innerHTML = value.substring(0, maxLength);
      setValue(value);
      return;
    }
    chatForm.current.classList.remove('max-length');
    setValue(value);
  };

  const onKeyPress = e => {
    if (e.key !== 'Enter') {
      let timeNow = Date.now().toString();
      var timerMark = parseInt(timeNow.slice(timeNow.length - 4, timeNow.length));
      if (timerMark - lastActiveTimer.current.value > 100) {
        if (lastActiveTimer.current.value === 0) {
          mqttClient.publish('chat/' + currentTopic.id + '/act',
            JSON.stringify({
              topic: 'chat/' + currentTopic.id + "/act",
              message: {
                id: userObject.current.id,
                alias: userObject.current.alias,
                activity: 'active'
              }
            }));
        }
        clearTimeout(lastActiveTimer.current.id);
        lastActiveTimer.current.id = setTimeout(
          () => {
            mqttClient.publish('chat/' + currentTopic.id + '/act',
              JSON.stringify({
                topic: 'chat/' + currentTopic.id + "/act",
                message: {
                  id: userObject.current.id,
                  alias: userObject.current.alias,
                  activity: 'inactive'
                }
              }));
            lastActiveTimer.current.value = 0;
          }
          , 400);
        lastActiveTimer.current.value = parseInt(timerMark);
      }
    }
  }

  const toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    ['blockquote', 'code-block']
  ];

  var attachDisabled = currentTopic.id  === null || isPending;
  var isDisabled = attachDisabled || isPending || value === "<p><br></p>" || value === '';
  var buttonOKClasses = props.editMessageId !== null ? "update" : "send";
  if (isPending) {
    buttonOKClasses += " sending";
  }

  return (
    <div className="chat-form" ref={chatForm}>
      <form
        onSubmit={handleSubmit}
      >
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyPress}
          placeholder={placeholder}
          modules={{ toolbar: toolbar }}
          readOnly={currentTopic.id === null}
        />
        <div className={"action-buttons"}>
          <div className={'attachment-button-block'}>
            <button
              className={"attach"}
              onClick={(e) => {
                e.preventDefault();
                dropzoneRef.current.click(e);
              }}
              disabled={attachDisabled}
            >
            </button>
            <div className={'attachment-info'}>
              <span>
                Maximum file size {maxFilesize / 1024 / 1024}MB
              </span>
              <span>
                Maximum files {maxFiles}
              </span>
            </div>
          </div>
          <div className={"attached-documents"}>
            <ul className={"document-list"}>
              {attachedDocuments.document.map((file, index) =>
                <AddedDocumentComponent
                  ref={e => documentRefs.current[file.name] = e}
                  file={file}
                  key={index}
                  removeFile={() => removeFile(file)}
                  progress={documentUploadProgress}
                />
              )}
            </ul>
          </div>
          <div className={isDisabled ? "action-button-block disabled" : "action-button-block"}>
            <button
              className={buttonOKClasses}
              disabled={isDisabled}
            >{props.editMessageId !== null ? "Update" : "Send"}</button>
            <button
              className={"clear"}
              onClick={clearWYSIWYG}
              disabled={isDisabled}
            >{props.editMessageId !== null ? "Cancel" : "Clear"}</button>
          </div>
        </div>
      </form>
    </div>
  );
});

export default QuillComponent;