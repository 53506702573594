import { useState, useEffect } from 'react';


const ErrorNotice = props => {
    const [error, setError] = useState(null);

    useEffect(() => {
        if (props.error !== null) {
            setError(props.error);
        }
    }
        , [props.error]);

    const clearError = () => {
        props.setError(null);
        setError(null);
    }

    return (
        <div className="error-notice">
            {error !== null && <div className="error-message">
                <span>{error}</span>
                <span className="close" onClick={clearError}></span>
            </div>}
        </div>
    )
}

export default ErrorNotice;