import React, { useState, useEffect, useRef, useContext } from 'react';

const HelpComponent = (props) => {

    const { helpVisible, setHelpVisible, ChatContext } = props;
    const [showHelp, setShowHelp] = useState(helpVisible);
    const helpRef = useRef(null);
    const theme = useContext(ChatContext);

    useEffect(() => {
        if (helpRef.current) {
            setTimeout(() => {
                helpRef.current.style.transition = 'all 0.4s ease-in-out';
            }, 10);
        }
    }
        , [helpRef]);

    useEffect(() => {
        setShowHelp(helpVisible);
    }, [helpVisible]);

    useEffect(() => {
        setHelpVisible(showHelp);
        if (showHelp) {
            helpRef.current.style.left = '1px';
        } else {
            helpRef.current.style.left = '100%';
        }
    }
        , [showHelp, setHelpVisible]);

    return (
        <div className={'chat-help ' + theme} ref={helpRef}>
            <div className={'help-button'} onClick={() => setShowHelp(false)}>
                <span>Hide Help</span>
            </div>
            <iframe src='/help.html' width='100%' height='100%' title='Help'></iframe>
        </div>
    );
}

export default HelpComponent;