import React, { useState, useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import MessageDocumentComponent from './messagedocument';
import MessageMediaComponent from './messagemedia';

const MessageLineComponent = props => {

  const { message, data } = props;
  const getHistory = props.data.setGetHistory;
  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (message.message.document !== undefined)
      if (message.message.document.length > 0) {
        var mediaAttachments = [];
        var documentAttachments = [];
        message.message.document.forEach(document => {
          if (document.type.startsWith('image/') || document.type.startsWith('video/')) {
            mediaAttachments.push(document);
          } else {
            documentAttachments.push(document);
          }
        });
        setMedia(mediaAttachments);
        setDocuments(documentAttachments);
      }
  }, [message.message.document]);

  return (
    <>
      {message.message.more ?
        <div className='chat-message more-messages' onClick={() => { setLoading(true); getHistory({ ...message.message.more }) }}>
          {!loading ? <span>Load Previous Messages</span> : <span>Loading...</span>}
        </div> : null}
      {message.user ?
        <div ref={messageRef} className={'chat-message'} data-timestamp={message.timestamp}>
          <strong className='message-alias'>
            {message.message.alias}
          </strong>
          <div className='message-time'>
            {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </div>
          {message.delete ?
            <div className='message-options'>
              <span
                onClick={() => {
                  message.edit(message.timestamp);
                }}>
              </span>
              <span
                onClick={() => {
                  message.delete(message.timestamp);
                }}>
              </span>
            </div>
            : null}
          <div className={'message-content'}>
            {parse(message.message.text)}
          </div>
          {media.length > 0 ?
            media.map((item, index) =>
              <MessageMediaComponent
                key={message.timestamp.toString() + index.toString() + "_media"}
                media={item}
                index={index}
              />
            )
            :
            null}
          {documents.length > 0 ?
            documents.map((document, index) =>
              <MessageDocumentComponent
                key={message.timestamp.toString() + index.toString() + "_document"}
                attachment={document}
                data={data}
                index={index}
              />
            )
            :
            null}
          {message.message.edited ? <div className='message-edited' draggable='false'>edited</div> : null}
        </div >
        :
        <div ref={messageRef} className={'chat-message-day-separator'}>
          <span>{new Date(message.timestamp).toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric' })}</span>
        </div>
      }
    </>
  );
}

export default MessageLineComponent;