import pdf from '../../assets/icons/pdf.svg';
import word from '../../assets/icons/word.svg';
import excel from '../../assets/icons/excel.svg';
import powerpoint from '../../assets/icons/powerpoint.svg';
import text from '../../assets/icons/text.svg';
import archive from '../../assets/icons/archive.svg';
import axios from 'axios';

function MessageDocumentComponent(props) {
    const {
        data,
        attachment,
        index
    } = props;

    const documentIcons = {
        'pdf': 'pdf',
        'doc': 'word',
        'docx': 'word',
        'xls': 'excel',
        'xlsx': 'excel',
        'csv': 'excel',
        'ppt': 'powerpoint',
        'pptx': 'powerpoint',
        'txt': 'text',
        'rtf': 'text',
        'bz2': 'archive',
        'gz': 'archive',
        'tar': 'archive',
        'zip': 'archive',
        '7z': 'archive',
        'rar': 'archive'
    }

    const documentIconSvgs = {
        'pdf': pdf,
        'word': word,
        'excel': excel,
        'powerpoint': powerpoint,
        'text': text,
        'archive': archive
    }

    const streamFileDownload = file => {
        return function (e) {
            e.preventDefault();
            let url = data.url + '?file=' + encodeURIComponent(file.path) + '&type=get';
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "Accept": "application/json",
                    "Authorization": data.t,
                    "uuid": data.id,
                }
            })
                .then(res => res.json())
                .then(async tokens => {
                    await axios.get(tokens.url, {
                        responseType: 'blob'
                    })
                        .then(response => {
                            const a = document.createElement("a");
                            a.style.display = "none";
                            document.body.appendChild(a);
                            const fileStream = new Blob([response.data], { type: file.type });
                            const url = window.URL.createObjectURL(fileStream);
                            a.href = url;
                            a.download = file.name;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    return (
        <div key={data.id + index} className={"message-attachment-link message-attachment-link-download"}>
            <span draggable="false" className={"file-download"} onClick={streamFileDownload(attachment)}>
                <img
                    src={documentIconSvgs[documentIcons[attachment.name.split(".")[attachment.name.split(".").length - 1]]]}
                    alt={attachment.name}
                />
                {attachment.name}
            </span>
        </div>
    )
}

export default MessageDocumentComponent;