const InfoComponent = props => {

  const {
    topicClient,
    alias,
    setAudioEnabled,
    audioEnabled,
    reconnectCount,
    setHelpVisible,
    setTheme
  } = props;

  return (
    <div className={reconnectCount !== null && reconnectCount < 3 ? "chat-header reconnecting" : "chat-header"}>
      <div className="help-button"
        onClick={
          () => setHelpVisible(true)}>
        <span></span>
      </div>
      <div className={"theme-button"}>
        <span className={'toggle-outer'}>
          <span className={'toggle-inner'}
            onClick={() =>
              setTheme(theme => { return theme === 'dark' ? 'light' : 'dark' })
            }>
            <span className={'toggle-handle'}>
              <span className={'toggle-handle-inner'}>
              </span>
            </span>
          </span>
        </span>
      </div>
      <h2>
        {topicClient.id !== null ?
          <>
            <span>{topicClient.name.replace('chat/', '')}
            </span>
          </>
          : <span>No Active Channel Selected</span>}
      </h2>
      <div className="logged-in-as">
        <strong>{alias}</strong>
      </div>
      <div className="audio-option">
        <span
          className={audioEnabled ? 'audio-enabled' : 'audio-disabled'}
          onClick={
            () => setAudioEnabled(!audioEnabled)}>
        </span>
      </div>
    </div>
  )
}

export default InfoComponent;