import React, { useEffect, useRef } from 'react';

const SidePanelChannels = props => {
    const { channelList, panelRef, setTopicClient, topicClient, loadingChannel } = props;
    const channelName = useRef([]);

    useEffect(() => {
        if (panelRef !== undefined
            && panelRef.style.marginTop !== undefined
            && panelRef.style.marginTop !== ''
            && panelRef.style.marginTop !== '0px'
            && panelRef.style.marginTop !== '0') {
            panelRef.style.marginTop = -panelRef.clientHeight + 'px';
        }
    }, [channelList, panelRef]);

    return (
        <>
            {channelList !== undefined && topicClient !== undefined && channelList.map(channel => {
                if (channel.name.substr(channel.id.length - 3, 3) !== 'act' && channel.id.substr(0, 7) !== 'chat/d/') {
                    var className = 'topic ';
                    if (channel.new) {
                        className += 'new  ';
                    }
                    if (channel.unread) {
                        className += 'unread';
                    }
                    return (
                        <div className={className} key={channel.id + "ci"} ref={el => channelName.current[channel.id] = el}
                            onClick={(e) => {
                                e.preventDefault();
                                setTopicClient(channel);
                            }}>
                            <span className={topicClient.id === channel.id ? "active-topic" : null}>
                                {channel.name.replace('chat/', '')}
                                {loadingChannel && topicClient.id === channel.id ? <span className="loading"></span> : null}
                            </span>
                        </div>
                    );
                }
                return false;
            })}
        </>
    )
}

export default SidePanelChannels;