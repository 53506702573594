import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Chat from './chat';

const root = ReactDOM.createRoot(document.getElementById('chat-root'));
root.render(
<StrictMode>
    <Chat />
</StrictMode>
);