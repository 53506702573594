import React, { useRef, useEffect, useState, forwardRef } from 'react';

const SidePanelUnfold = forwardRef((props, ref) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { sidePanel, topicClient } = props;
    const debounceTimeout = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const unfoldBar = sidePanel.current.querySelector('.unfold-bar');
            if (window.getComputedStyle(unfoldBar).display === 'none') {
                sidePanel.current.classList.remove('unfold');
                setMenuOpen(false);
                clearTimeout(debounceTimeout.current);
                debounceTimeout.current = setTimeout(() => {
                    sidePanel.current.style.height = '100%';
                }, 20);
            }
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }, [sidePanel, menuOpen, ref]);


    useEffect(() => {
        const unfoldBar = sidePanel.current.querySelector('.unfold-bar');
        if (unfoldBar === null) return;
        const isDisplayNone = window.getComputedStyle(unfoldBar).display === 'none';
        if (!isDisplayNone) {
            setMenuOpen(false);
        }
    }, [topicClient, sidePanel]);


    useEffect(() => {
        const unfoldBar = sidePanel.current.querySelector('.unfold-bar');
        const isDisplayNone = window.getComputedStyle(unfoldBar).display === 'none';
        if (menuOpen) {
            sidePanel.current.classList.add('unfold');
            if (isDisplayNone) {
                sidePanel.current.style.height = '100%';
            } else {
                var openHeight = document.querySelector('.chat-messages').clientHeight + 34;
                sidePanel.current.style.height = openHeight + 'px';
            }
        } else {
            sidePanel.current.classList.remove('unfold');
            if (isDisplayNone) {
                sidePanel.current.style.height = '100%';
            } else {
                sidePanel.current.style.height = 0;
            }
        }
    }, [menuOpen, sidePanel]);

    return (
        <div className={'chat-unfold'}
            ref={ref}
            onClick={(e) => {
                if (window.getComputedStyle(sidePanel.current.querySelector('.unfold-bar')).display === 'none') {
                    return;
                }
                e.preventDefault();
                e.bubbles = false;
                setMenuOpen(!menuOpen)
            }}
        >
        </div>
    );
});

export default SidePanelUnfold;