import { forwardRef, useState, useEffect } from 'react';

const AddedDocumentComponent = forwardRef((props, ref) => {
    const file = props.file;
    const progress = props.progress;
    const [progressState, setProgressState] = useState(0);

    useEffect(() => {
        if(progress !== undefined) {
            setProgressState(progress[file.name]);
        }
    }
        , [progress]);

    return (
        <li
            ref={ref}
            key={file.name}
        >
            {file.name}
            <span
                onClick={() => props.removeFile()}
                className={"remove-file"}>
            </span>
            <div className={"progress-bar"}>
                <div
                    className={"progress-bar-fill"}
                    style={{ width: progressState + '%' }}>
                </div>
            </div>
        </li>
    );
});

export default AddedDocumentComponent;