import React, { useEffect, useState, useRef, forwardRef } from 'react'
import Dropzone from 'react-dropzone'

const FileDropzone = forwardRef((props, ref) => {
  const [files, setFiles] = useState([]);
  const currentFiles = useRef([]);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const {
    attachedDocuments,
    setAttachedDocuments,
    signedEndpoint,
    userObject
  } = props;

  useEffect(() => {
    if (signedEndpoint === undefined || userObject === undefined) return;
    let url = signedEndpoint + "?accepted=true";
    let fileSignedUrl = {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Accept": "application/json",
        "Authorization": userObject.current.t,
        "uuid": userObject.current.id,
      }
    };

    return fetch(url, fileSignedUrl)
      .then(res => res.json())
      .then(accepted => {
        setAcceptedFiles(accepted.message);
      });
  }, [signedEndpoint, userObject]);

  useEffect(() => {
    currentFiles.current = attachedDocuments.document;
  }
    , [attachedDocuments]);

  useEffect(() => {
    setAttachedDocuments({ document: files });
  }
    , [files, setAttachedDocuments]);

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    var newFiles = [];
    var filePromises = [];
    ref.current.classList.remove("drophover");

    if(currentFiles.current.length + acceptedFiles.length > props.maxFiles) {
      props.setError('Maximum of 5 files allowed');
      return;
    }

    acceptedFiles.forEach((file) => {
      filePromises.push(new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = function (e) {
            resolve({
              name: file.name,
              contents: e.target.result,
              type: file.type,
              file: file
            });
          }
          reader.readAsArrayBuffer(file);
        } catch (e) {
          reject(e);
        }
      }).then((file) => {

        if (currentFiles.current.some((f) => f.name === file.name)) {
          currentFiles.current = currentFiles.current.filter((f) => f.name !== file.name);
        }

        newFiles.push(file);
        return file;
      }));
    });
    await Promise.all(filePromises);
    currentFiles.current = currentFiles.current.concat(newFiles);
    setFiles(currentFiles.current);
  }

  const onDropRejected = e => {
    switch (e[0].errors[0].code) {
      case 'file-invalid-type':
        props.setError('Invalid file type');
        break;
      case 'file-too-large':
        props.setError('Maximum file upload size is ' + (props.maxFilesize / 1024 / 1024) + 'MB');
        break;
      default:
        props.setError('An error has occurred');
        break;
    }
    return;
  }

  return (
    <Dropzone
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      accept={acceptedFiles}
      maxSize={props.maxFilesize} // 10MB
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} ref={ref} className={'dropzone'}>
          <input {...getInputProps()} />
          {
            <div className={"dropstyleactive"}>
              <div className={"blind"}>
              </div>
              <div className={"notice"}>
                Drag 'n' drop some files here
              </div>
            </div>
          }
        </div>
      )}
    </Dropzone>
  )
});

export default FileDropzone;